import { Button, Grid, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import { useAppDispatch } from "../../store";
import { useNavigate } from "react-router-dom";
import ListDataSkeleton from "../../components/template/skeleton/ListDataSkeleton";
import AddIcon from "@mui/icons-material/Add";
import UserAccountSearch from "../../components/molecules/userAccount/UserAccountSearch";
import UserAccountTable from "../../components/molecules/userAccount/UserAccountTable";
import { useUserAccListQuery } from "../../store/feature/service/UserAccountApiSlice";
// import { useGetAllOcspListQuery } from '../../store/feature/service/satpasDeviceApiSlice';

const UserAccount = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const [params, setParams] = useState<object>({});
  const [defaultValue, setDefaultValue] = useState({});
  // const { isLoadingOcspConfig, isLoadingEvent, eventFlag } = useAppSelector(
  //   (state: RootState) => state.satpasDevice
  // );

  // const data: any = []
  // REGION: DISPATCH OCSP ALL
  // useEffect(()=> {
  //   dispatch(getAllOcspList(params))
  // },[params])

  const { data, isLoading } = useUserAccListQuery(params);

  // REGION: DISPATCH SUCCESS EVENT
  useEffect(() => {
    if (data) {
      console.log("data baru ===> ", data);
    }
  }, [data]);

  // REGION: RETURN TSX
  return (
    <ListDataSkeleton
      isLoading={isLoading}
      // isLoadingEvent={isLoadingEvent}
      titleWidth={"300px"}
    >
      <Grid container>
        <Grid item xs={10} md={10} lg={10} xl={10}>
          <Typography
            variant="h3"
            fontFamily="Open Sans"
            sx={{ marginY: "20px" }}
          >
            User Account
          </Typography>
        </Grid>

        <Grid
          item
          xs={2}
          md={2}
          lg={2}
          xl={2}
          display={"flex"}
          justifyContent={"center"}
          alignItems={"center"}
        >
          <Button
            variant="contained"
            color="primary"
            onClick={() => navigate("/user-account/create")}
            sx={{ marginBottom: "18px" }}
          >
            <AddIcon /> Create
          </Button>
        </Grid>
        <Grid item xs={12} md={12} lg={12} xl={12}>
          <UserAccountSearch params={params} setParams={setParams} />
          <UserAccountTable
            data={data !== undefined && data !== null ? data?.data : []}
            params={params}
            setParams={setParams}
            defaultValue={defaultValue}
            setDefaultValue={setDefaultValue}
          />
        </Grid>
      </Grid>
    </ListDataSkeleton>
  );
};

export default UserAccount;
