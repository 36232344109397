import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import {
  clearStorage,
  getRefreshToken,
  getToken,
} from "../../../utils/storage";
import Swal from "sweetalert2";

const baseQuery = fetchBaseQuery({
  baseUrl: `${process.env.REACT_APP_BASE_API}/`,
  prepareHeaders: (headers, { getState, endpoint }) => {
    const token = getToken();
    const refreshToken = getRefreshToken();
    if (process.env.NODE_ENV === "development") {
      headers.set("x-mock-response-code", "200");
    }
    if (token) {
      headers.set("Authorization", `Bearer ${token}`);
    }
    // if (endpoint === "logout") {
    //   headers.set("Refresh_token", refreshToken);
    // }
    return headers;
  },
});

const baseQueryWithReauth = async (args: any, api: any, extraOptions: any) => {
  let result = await baseQuery(args, api, extraOptions);
  // console.log('result here brother ==> ', result?.error?.status)
  // let error: any = result?.error?.status;
  // console.log('haloooo ===> ', result?.error)
  let error: any = result?.error;
  if (error?.status === 401) {
    clearStorage();
    window.location.href = "/login";
  }
  return result;

  // if (
  //   error?.error === "tokenization Error: Access token expired" ||
  //   error?.error === "error on finding user" ||
  //   error?.error === "user is not active" ||
  //   error?.error === "user is not authorized to this application"
  // ) {
  //   clearStorage();
  //   window.location.href = "/login";
  // } else if (error?.error === "unauthorized user role") {
  //   Swal.fire({
  //     icon: "error",
  //     title: "Unauthorized Role",
  //     showCancelButton: false,
  //     confirmButtonText: "OK",
  //     confirmButtonColor: "#051438",
  //     text: "unauthorized user role",
  //   }).then(() => {
  //     clearStorage();
  //     window.location.href = "/login";
  //   });
  // }
  // return result;
};

export const apiSlice = createApi({
  baseQuery: baseQueryWithReauth,
  endpoints: (builder) => ({}),
  tagTypes: [
    "GetLivenessDeviceList",
    "GetLogEventsList",
    "UpdatePassword",
    "logoutUser",
    "GetImportKeysList",
    "DeleteImportKey",
    "ExportRsa",
    "ExportEcdsa",
    "ImportEcdsa",
    "ImportRsa",
    "submitLogin",
    "GetDashboardInfo",
    "GetDddFieldsList",
    "CreateDddFields",
    "GetDddFieldByUid",
    "UpdateDddFields",
    "GetKeysListMng",
    "DeleteKeyMng",
    "GetKeysListMngByUid",
    "GetDACert",
    "DeleteDddFields",
    "CreateParamDef",
    "CreateParamRef",
    "GetDddFieldsTypeList",
    "GetParamDefUID",
    "GetParamDefByUID",
    "DeleteParamSpec",
    "CreateParamSpec",
    "UpdateParamSpec",
    "UpdateParamDef",
    "DeleteParamDef",
    "GenerateKeyDddFields",
    "RevokeKeysListMngByUid",
    "DownloadCSR",
    "GetDigsigUidNames",
    "UploadCertDig",
    "DeleteCertDig",
    "GetCertByUid",
    "UpdateCertDig",
    "GetGeneratedDigsig",
    "DeleteGeneratedDigsig",
    "GetDddParamDefUid",
    "DownloadGenCSV",
    "CreateGenDig",
    "DownloadGenDigsig",
    "GenDigsigErrLog",
    "DeleteGenDigsigErrLog",
    "GetGeneratedDigsigDetail",
    "CreateEntryAndVal",
    "UpdateEntryAndVal",
    "PostGenDigsig",
    "GetCompListUid",
    "CreateDACert",
    "GetDACertByUid",
    "UpdateDACert",
    "GetKeySignList",
    "UserAccList",
    "CreateUserAcc",
    "UpdateUserAcc",
    "RoleList",
    "PageMasterList",
    "CreatePageMaster",
    "UpdatePageMaster",
    "DeletePageMaster",
    "CreatePageMasterEndpoint",
    "GetPageMastrEndpntByUid",
    "PageAccessList",
    "RoleAccessList",
    "DownloadGenDigsigEntryComp",
    "PageMasterListNonPage",
    "CreateAdmRole",
    "createPageAccess",
    "GetPageAccsByUid",
    "UpdateAdmRole",
    "UpdatePageAccess",
  ],
});

export const { endpoints, reducerPath, reducer, middleware } = apiSlice;
