// import { instance, instanceEndUser } from "./service/instance";
import { apiSlice } from "../api/apiSlice";

export const UserAccountApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    userAccList: builder.query<any, any>({
      query: (params) => {
        return {
          url: `/user-admin/list`,
          params: params,
        };
      },
      providesTags: ["UserAccList"],
    }),
  //   getDACertByUid: builder.query<any, any>({
  //     query: (params) => {
  //       return {
  //         url: `/da-certificate/detail/${params}`,
  //         method: "GET",
  //       };
  //     },
  //     providesTags: ["GetDACertByUid"],
  //   }),
    createUserAcc: builder.mutation<any, any>({
      query: (body) => {
        return {
          url: `/user-admin/create`,
          method: "POST",
          body,
        };
      },
      invalidatesTags: ["CreateUserAcc", "UserAccList"],
    }),
    updateUserAcc: builder.mutation<any, any>({
      query: ({ id, body }) => {
        return {
          url: `/user-admin/update/${id}`,
          method: "PUT",
          body,
        };
      },
      invalidatesTags: ["UpdateUserAcc", "UserAccList"],
    }),
  }),
});

export const {
  useUserAccListQuery,
  useCreateUserAccMutation,
  // useGetDACertByUidQuery,
  useUpdateUserAccMutation,
} = UserAccountApiSlice;
