import { Box, Button, Chip, Stack, Typography } from "@mui/material";
import { DataGrid, GridColDef, GridCellParams } from "@mui/x-data-grid";
import { useNavigate } from "react-router-dom";
import { RootState, useAppDispatch, useAppSelector } from "../../../store";
// import { deleteOcspConfiguration } from '../../../store/feature/satpasDevice';
import PaginationControl from "../pagination/Pagination";
import Swal from "sweetalert2";
import { useDeleteKeyMngMutation } from "../../../store/feature/service/keyApiSlice";
import { useEffect, useState } from "react";
import { useDeletePageMasterMutation } from "../../../store/feature/service/PageMaster";

const PageMasterTable = ({ data, params, setParams }: any) => {
  // REGION: INIT VARIABLES
  const navigate = useNavigate();
  const [pemName, setPemName] = useState("");
  // const { totalConfig } = useAppSelector(
  //   (store: RootState) => store.satpasDevice
  // );
  const [deletePageMaster, { isSuccess, isError }] =
    useDeletePageMasterMutation();

  // REGION: RENDER BUTTON CELL
  const customCellRender = (params: GridCellParams) => {
    // REGION: NAVIGATE TO EDIT
    const onEdit = (e: any) => {
      e.stopPropagation(); // don't select this row after clicking
      navigate(`/page-master/edit/${params.row.uid}`, { state: params.row });
    };

    const onEndpoint = (e: any) => {
      e.stopPropagation(); // don't select this row after clicking
      navigate(`/page-master/endpoint/${params.row.uid}`, { state: params.row });
    };

    // REGION: DISPATCH DELETE DATA
    const onDelete = (e: any) => {
      e.stopPropagation(); // don't select this row after clicking
      Swal.fire({
        title: "Delete Confirmation",
        text: "Are you sure delete this configuration? You won't be able to revert this!",
        icon: "question",
        showCancelButton: true,
        confirmButtonColor: "#d33",
        cancelButtonColor: "#808080",
        confirmButtonText: "Yes",
      }).then((result) => {
        if (result.isConfirmed) {
          deletePageMaster(params.row.uid);
        }
      });
    };

    // REGION: RETURN TSX
    return (
      <>
        <Stack spacing={2} direction="row">
          <Button variant="contained" onClick={onEdit}>
            Update
          </Button>
          <Button variant="contained" color="gray" onClick={onEndpoint}>
            Endpoint
          </Button>
          <Button variant="contained" color="error" onClick={onDelete}>
            Delete
          </Button>
        </Stack>
      </>
    );
  };

  // REGION: INIT COLUMNS
  const columns: GridColDef[] = [
    {
      field: "name",
      headerName: "Name",
      flex: 1,
      minWidth: 250,
      headerClassName: "centered",
    },
    {
      field: "accessUrl",
      headerName: "Access URL",
      flex: 1,
      minWidth: 250,
    },
    {
      field: "createdAt",
      headerName: "Created At",
      flex: 1,
      minWidth: 250,
    },
    {
      field: "status",
      headerName: "Status",
      flex: 1,
      minWidth: 200,
      headerAlign: "center",
      align: "center",
      renderCell: (params: any) => {
        if (params.row.status !== null) {
          switch (params.row.status.toLowerCase()) {
            case "active": {
              return <Chip label="Active" color="success" variant="outlined" />;
            }
            case "inactive": {
              return (
                <Chip label="Inactive" color="warning" variant="outlined" />
              );
            }
            case "deleted": {
              return <Chip label="Deleted" color="error" variant="outlined" />;
            }
          }
        } else {
          return "";
        }
      },
    },
    {
      field: "action",
      headerName: "Action",
      flex: 1,
      minWidth: 320,
      headerAlign: "center",
      align: "center",
      sortable: false,
      renderCell: customCellRender,
    },
  ];

  useEffect(() => {
    if (isSuccess) {
      Swal.fire({
        icon: "success",
        title: "Success Delete",
        showCancelButton: false,
        confirmButtonText: "OK",
        confirmButtonColor: "#051438",
        text: "Success Delete Page Master",
      }).then(() => navigate("/page-master"));
    }
  }, [isSuccess]);

  useEffect(() => {
    if (isError) {
      Swal.fire({
        icon: "error",
        title: "Error Delete",
        showCancelButton: false,
        confirmButtonText: "OK",
        confirmButtonColor: "#051438",
        text: "Error Delete Page Master",
      });
    }
  }, [isError]);
  // REGION: INIT ROWS
  console.log("data form table ===> ", data?.data);
  const dataRows =
    data?.data !== null && data?.data !== undefined ? data?.data : [];
  const rows = dataRows.map((row: any, index: any) => ({
    // uid: row.uc_ocsp_config_uid,
    id: index,
    uid: row.pge_master_uid,
    name: row.pge_master_name,
    accessUrl: row.pge_master_access_url,
    createdAt: row.pge_master_created_at,
    status: row.pge_master_status,
    code: row.pge_master_code,
  }));

  // REGION: RETURN TSX
  return (
    <>
      <Box sx={{ height: "650px" }}>
        <DataGrid
          rows={rows}
          columns={columns}
          disableColumnMenu
          hideFooter
          disableColumnSelector
          disableDensitySelector
          disableSelectionOnClick
          // getRowHeight={() => "auto"}
          // sx={{
          //   "& .MuiDataGrid-cell": {
          //     padding: "16px", // Adjust the padding as needed
          //   },
          // }}
        />
      </Box>
      <PaginationControl
        params={params}
        setParams={setParams}
        total={data.total_records}
      />
    </>
  );
};

export default PageMasterTable;
