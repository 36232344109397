import { Box, Button, Stack, Checkbox } from "@mui/material";
import { DataGrid, GridColDef, GridCellParams } from "@mui/x-data-grid";
import { useNavigate } from "react-router-dom";
import { useState, useEffect } from "react";
import Swal from "sweetalert2";
import { useDeletePageMasterMutation } from "../../../store/feature/service/PageMaster";

const PageAccessTableCreate = ({
  data,
  dataPgeAccess,
  params,
  setParams,
}: any) => {
  const navigate = useNavigate();
  const [deletePageMaster] = useDeletePageMasterMutation();

  // STATE TO HANDLE ROW SELECTION
  const [rowSelection, setRowSelection] = useState<any>({});

  useEffect(() => {
    if (dataPgeAccess?.data) {
      console.log("dataPgeAccess table ==> ", dataPgeAccess);

      const initializedSelection = dataPgeAccess?.data
        ?.map((row: any) => ({
          [row.pge_master_uid]: {
            create: row.adm_pge_role_create === 1, // true if 1, false if 0
            read: row.adm_pge_role_read === 1,
            update: row.adm_pge_role_update === 1,
            delete: row.adm_pge_role_delete === 1,
          },
        }))
        .reduce((acc: any, curr: any) => ({ ...acc, ...curr }), {});

      setRowSelection(initializedSelection);
    }
  }, [dataPgeAccess]);

  useEffect(() => {
    if (data) {
      console.log("data table==> ", data);
      const initializedSelection = data?.data?.reduce((acc: any, row: any) => {
        acc[row.pge_master_uid] = {
          create: false,
          read: false,
          update: false,
          delete: false,
        };
        return acc;
      }, {});

      setRowSelection(initializedSelection);
    }
  }, [data]);

  const handleCheckboxChange = (event: any, row: any, field: string) => {
    const { checked } = event.target;
    setRowSelection((prev: any) => {
      const updatedSelection = {
        ...prev,
        [row.uid]: {
          ...prev[row.uid],
          [field]: checked, // Update the clicked checkbox
        },
      };

      // Generate the output, ensuring all checkboxes are included
      const selectedRows = Object.entries(updatedSelection).map(
        ([uid, fields]: any) => ({
          adm_pge_role_create: fields.create ? 1 : 0,
          adm_pge_role_delete: fields.delete ? 1 : 0,
          adm_pge_role_read: fields.read ? 1 : 0,
          adm_pge_role_update: fields.update ? 1 : 0,
          pge_master_uid: uid,
        })
      );

      setParams(selectedRows);
      return updatedSelection;
    });
  };

  const columns: GridColDef[] = [
    {
      field: "pageName",
      headerName: "Page Name",
      flex: 3,
      minWidth: 500,
      headerClassName: "centered",
    },
    {
      field: "create",
      headerName: "Create",
      headerAlign: "center",
      align: "center",
      flex: 1,
      minWidth: 250,
      renderCell: (params: GridCellParams) => (
        <Checkbox
          checked={rowSelection[params.row.uid]?.create || false}
          onChange={(e) => handleCheckboxChange(e, params.row, "create")}
        />
      ),
    },
    {
      field: "read",
      headerName: "Read",
      headerAlign: "center",
      align: "center",
      flex: 1,
      minWidth: 250,
      renderCell: (params: GridCellParams) => (
        <Checkbox
          checked={rowSelection[params.row.uid]?.read || false}
          onChange={(e) => handleCheckboxChange(e, params.row, "read")}
        />
      ),
    },
    {
      field: "update",
      headerName: "Update",
      headerAlign: "center",
      align: "center",
      flex: 1,
      minWidth: 250,
      renderCell: (params: GridCellParams) => (
        <Checkbox
          checked={rowSelection[params.row.uid]?.update || false}
          onChange={(e) => handleCheckboxChange(e, params.row, "update")}
        />
      ),
    },
    {
      field: "delete",
      headerName: "Delete",
      headerAlign: "center",
      align: "center",
      flex: 1,
      minWidth: 250,
      renderCell: (params: GridCellParams) => (
        <Checkbox
          checked={rowSelection[params.row.uid]?.delete || false}
          onChange={(e) => handleCheckboxChange(e, params.row, "delete")}
        />
      ),
    },
  ];

  useEffect(() => {
    if (dataPgeAccess) {
      // console.log('dataPgeAccess ===> ', JSON.stringify(dataPgeAccess?.data))
    }
  }, [dataPgeAccess]);

  // Initialize rows based on data
  // console.log("data haha ==>  ", data);
  const rows =
    data?.data?.map((row: any, index: any) => ({
      id: index,
      uid: row.pge_master_uid,
      pageName: row.pge_master_name,
    })) || [];

  return (
    <Box sx={{ height: "450px" }}>
      {rowSelection && (
        <DataGrid
          rows={rows}
          columns={columns}
          disableColumnMenu
          hideFooter
          disableColumnSelector
          disableDensitySelector
          disableSelectionOnClick
        />
      )}
    </Box>
  );
};

export default PageAccessTableCreate;
