import { yupResolver } from "@hookform/resolvers/yup";
import { Box, Button, FormLabel, Grid, Typography } from "@mui/material";
import { Stack } from "@mui/system";
import { useForm } from "react-hook-form";
import {
  InputForm,
  SelectFormm,
  TextAreaForm,
} from "../../components/molecules/forms/index";
import { useLocation } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { useAppDispatch } from "../../store";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import DetailDataSkeleton from "../../components/template/skeleton/DetailDataSkeleton";
import WarningIcon from "@mui/icons-material/Warning";
import Swal from "sweetalert2";
import { renderToStaticMarkup } from "react-dom/server";
import {
  useCreatePageMasterMutation,
  usePageMasterListNonPageQuery,
  useUpdatePageMasterMutation,
} from "../../store/feature/service/PageMaster";
import PageAccessTableCreate from "../../components/molecules/pageAccess/PageAccessTableCreate";
import pageAccess from "../../utils/validation/pageAccess";
import {
  useCreateAdmRoleMutation,
  useUpdateAdmRoleMutation,
} from "../../store/feature/service/adminRole";
import {
  useCreatePageAccessMutation,
  useGetPageAccsByUidMutation,
  useUpdatePageAccessMutation,
} from "../../store/feature/service/PageAccess";

const PageAccessField = () => {
  // REGION: INIT VARIABLES
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const location = useLocation();
  const idParam = useParams();
  const { pathname, state } = location;
  const editFgroup = pathname.includes("edit");
  const [optAccGroup, setOtpAccGroup] = useState([]);
  const [params, setParams] = useState<any>([]);
  const [dataMaster, setDataMaster] = useState([]);

  const data: any = [];

  const {
    data: dataMasterTable,
    isError: errMasterTable,
    isSuccess: succMasterTable,
  } = usePageMasterListNonPageQuery({});
  const [
    CreateAdmRole,
    { data: dataCreate, isError: isErrorCreate, isSuccess: isSuccesCreate },
  ] = useCreateAdmRoleMutation();

  const [
    CreatePgeAccss,
    {
      data: dataCreatePgeAccss,
      isError: isErrorCreatePgeAccss,
      isSuccess: isSuccesCreatePgeAccss,
    },
  ] = useCreatePageAccessMutation();

  //useGetPageAccsByUidMutation
  const [getPgeAccsUid, { data: dataPgeAccsUid }] =
    useGetPageAccsByUidMutation();

  // useUpdateDACertMutation
  const [
    UpdateAdmRole,
    { data: dataUpdate, isError: isErrorUpdate, isSuccess: isSuccesUpdate },
  ] = useUpdateAdmRoleMutation();

  const [
    UpdatePgeAccss,
    {
      data: dataUpdatePgeAccss,
      isError: isErrorUpdatePgeAccss,
      isSuccess: isSuccesUpdatePgeAccss,
    },
  ] = useUpdatePageAccessMutation();
  // REGION: INIT FORM DATA
  const {
    control,
    reset,
    setValue,
    handleSubmit,
    formState: { errors },
  } = useForm<{
    accessGroupName: string;
    description: string;
    masterCode: string;
    status: string;
  }>({
    reValidateMode: "onChange",
    resolver: yupResolver(pageAccess),
    defaultValues: {
      accessGroupName: "",
      description: "",
      masterCode: "",
      status: "",
    },
  });

  useEffect(() => {
    if (state) {
      setValue("accessGroupName", state.name);
      setValue("description", state.accessUrl);
      setValue("masterCode", state.code);
      setValue("status", state.status);
      console.log("haha ===> ", state);
    }
  }, [state]);

  useEffect(() => {
    if (params) {
      console.log("params ==> ", params);
    }
  }, [params]);

  const optSelect = [
    {
      label: "ACTIVE",
      value: "active",
    },
    {
      label: "INACTIVE",
      value: "inactive",
    },
  ];

  // REGION: DISPATCH INSERT & UPDATE DATA

  const onSubmit = (e: any) => {
    // console.log("hahaha ===> ", e);
    let tempObj = {
      adm_role_description: e.description,
      adm_role_name: e.accessGroupName,
      adm_role_status: e.status,
    };
    if (editFgroup) {
      UpdateAdmRole({ id: idParam.id, body: tempObj });
    } else {
      CreateAdmRole(tempObj);
    }
  };
// REGION Create Page Access
  useEffect(() => {
    if (dataCreate) {
      const data = dataCreate?.data?.adm_role_uid;
      params.forEach((obj: any) => {
        obj.adm_role_uid = data;
      });
      CreatePgeAccss(params);
    }
  }, [dataCreate]);

  useEffect(() => {
    if (dataUpdate) {
      params.forEach((obj: any) => {
        obj.adm_role_uid = idParam.id;
      });
      UpdatePgeAccss({ id: idParam.id, body: params });
    }
  }, [dataUpdate]);

  useEffect(() => {
    if (editFgroup) {
      getPgeAccsUid(idParam.id);
    }
  }, []);

  useEffect(() => {
    if (dataPgeAccsUid) {
      const data = dataPgeAccsUid?.data;
      console.log("dataPgeAccsUid ===> ", dataPgeAccsUid?.data);
      setValue("accessGroupName", data[0].adm_role_name);
      setValue("description", data[0].adm_role_description);
      setValue("status", data[0].adm_role_status);
    }
  }, [dataPgeAccsUid]);

  useEffect(() => {
    if (dataMasterTable) {
      setDataMaster(dataMasterTable);
    }
  }, [dataMasterTable]);

  const showFirstAlert = () => {
    Swal.fire({
      title: "Your changes won’t be saved",
      html: `
            <p style="text-align: left;">
              We won’t be able to save your data if you move away from this page.
            </p>
          `,
      icon: "question",
      showCancelButton: true,
      confirmButtonColor: "#d33",
      cancelButtonColor: "#808080",
      iconHtml: renderToStaticMarkup(
        <WarningIcon style={{ fontSize: "50px", color: "#d33" }} />
      ),
      confirmButtonText: "Discard Data",
      cancelButtonText: "Go back",
      reverseButtons: true,
    }).then(async (result) => {
      if (result.isConfirmed) {
        navigate("/page-access");
      }
    });
  };

  const handleRevoke = (e: any) => {
    console.log("handle revoke ===> ");
    showFirstAlert();
  };

  useEffect(() => {
    if (isSuccesCreatePgeAccss) {
      Swal.fire({
        icon: "success",
        title: "Success Create",
        showCancelButton: false,
        confirmButtonText: "OK",
        confirmButtonColor: "#051438",
        text: "Success Create Page Access",
      }).then(() => navigate("/page-access"));
    }
  }, [isSuccesCreatePgeAccss]);

  useEffect(() => {
    if (isErrorCreatePgeAccss) {
      Swal.fire({
        icon: "error",
        title: "Error Create",
        showCancelButton: false,
        confirmButtonText: "OK",
        confirmButtonColor: "#051438",
        text: "Error Create Page Access",
      });
    }
  }, [isErrorCreatePgeAccss]);

  useEffect(() => {
    if (isErrorCreate) {
      Swal.fire({
        icon: "error",
        title: "Error Upload",
        showCancelButton: false,
        confirmButtonText: "OK",
        confirmButtonColor: "#051438",
        text: "Error Upload Page Master",
      });
    }
  }, [isErrorCreate]);

  useEffect(() => {
    if (isSuccesUpdate) {
      Swal.fire({
        icon: "success",
        title: "Success Update",
        showCancelButton: false,
        confirmButtonText: "OK",
        confirmButtonColor: "#051438",
        text: "Success Update User Account ",
      }).then(() => navigate("/page-access"));
    }
  }, [isSuccesUpdate]);

  useEffect(() => {
    if (isErrorUpdate) {
      Swal.fire({
        icon: "error",
        title: "Error Update",
        showCancelButton: false,
        confirmButtonText: "OK",
        confirmButtonColor: "#051438",
        text: "Error Update User Account ",
      });
    }
  }, [isErrorUpdate]);

  useEffect(() => {
    if (isErrorUpdatePgeAccss) {
      Swal.fire({
        icon: "error",
        title: "Error Update",
        showCancelButton: false,
        confirmButtonText: "OK",
        confirmButtonColor: "#051438",
        text: "Error Update Page Access ",
      });
    }
  }, [isErrorUpdatePgeAccss]);

  useEffect(() => {
    if (isSuccesUpdatePgeAccss) {
      Swal.fire({
        icon: "success",
        title: "Success Update",
        showCancelButton: false,
        confirmButtonText: "OK",
        confirmButtonColor: "#051438",
        text: "Success Update Page Access ",
      }).then(() => navigate("/page-access"));
    }
  }, [isSuccesUpdatePgeAccss]);

  // REGION: RETURN TSX
  return (
    <DetailDataSkeleton
      // isLoading={isLoadingOcspConfig}
      // isLoadingEvent={isLoadingEvent}
      inputCount={6}
      titleWidth={"350px"}
    >
      <Box component="form" onSubmit={handleSubmit(onSubmit)} paddingY="20px">
        <Grid container spacing={1}>
          <Grid item lg={12}>
            <Typography variant="h3" fontFamily="Open Sans" mb={3}>
              {editFgroup ? "Update Page Access" : "Create Page Access"}
            </Typography>
          </Grid>
          <Grid item lg={12}>
            <InputForm
              name="accessGroupName"
              label="Access Group Name"
              placeholder="Type Access Group Name"
              disabled={false}
              isLoading={false}
              control={control}
              errors={errors}
              maxLength={500}
              required
            />
          </Grid>
          <Grid item lg={12}>
            <TextAreaForm
              name="description"
              label="Description"
              placeholder="Type Description"
              maxLength={1000}
              disabled={false}
              isLoading={false}
              control={control}
              errors={errors}
              required
              defaultValue={""}
            />
          </Grid>
          <Grid item lg={12}>
            <FormLabel sx={{ marginBottom: "400px" }}>Access Rights</FormLabel>
            <PageAccessTableCreate
              data={
                dataMaster !== undefined && dataMaster !== null
                  ? dataMaster
                  : []
              }
              dataPgeAccess={
                dataPgeAccsUid !== undefined && dataPgeAccsUid !== null
                  ? dataPgeAccsUid
                  : []
              }
              params={params}
              setParams={setParams}
            />
          </Grid>
          <Grid item lg={12}>
            <SelectFormm
              name="status"
              label="Status"
              defaultValue={1}
              options={optSelect}
              placeholder="None"
              disabled={false}
              isLoading={false}
              control={control}
              errors={errors}
              required
              isClear
            />
          </Grid>
          <Grid item lg={12}>
            <Stack
              spacing={2}
              direction="row"
              alignItems="center"
              justifyContent="flex-end"
            >
              <Button
                variant="contained"
                type="button"
                color="gray"
                onClick={handleRevoke}
              >
                Cancel
              </Button>
              <Button variant="contained" type="submit">
                {editFgroup ? "Update" : "Submit"}
              </Button>
            </Stack>
          </Grid>
        </Grid>
      </Box>
    </DetailDataSkeleton>
  );
};

export default PageAccessField;
