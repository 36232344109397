import { Box, Button, Chip, Stack, Typography } from "@mui/material";
import { DataGrid, GridColDef, GridCellParams } from "@mui/x-data-grid";
import { useNavigate } from "react-router-dom";
import { RootState, useAppDispatch, useAppSelector } from "../../../store";
// import { deleteOcspConfiguration } from '../../../store/feature/satpasDevice';
import PaginationControl from "../pagination/Pagination";
import Swal from "sweetalert2";
import { useDeleteKeyMngMutation } from "../../../store/feature/service/keyApiSlice";
import { useEffect, useState } from "react";

const UserAccountTable = ({
  data,
  params,
  setParams,
  defaultValue,
  setDefaultValue,
}: any) => {
  // REGION: INIT VARIABLES
  const navigate = useNavigate();
  const [pemName, setPemName] = useState("");
  // const { totalConfig } = useAppSelector(
  //   (store: RootState) => store.satpasDevice
  // );
  const [deleteKey, { isSuccess, isError }] = useDeleteKeyMngMutation();


  // REGION: RENDER BUTTON CELL
  const customCellRender = (params: GridCellParams) => {
    // REGION: NAVIGATE TO EDIT
    const onEdit = (e: any) => {
      e.stopPropagation(); // don't select this row after clicking
      navigate(`/user-account/edit/${params.row.userId}`, {state: params.row});
    };

    // REGION: DISPATCH DELETE DATA
    const onDelete = (e: any) => {
      e.stopPropagation(); // don't select this row after clicking
      Swal.fire({
        title: "Delete Confirmation",
        text: "Are you sure delete this configuration? You won't be able to revert this!",
        icon: "question",
        showCancelButton: true,
        confirmButtonColor: "#d33",
        cancelButtonColor: "#808080",
        confirmButtonText: "Yes",
      }).then((result) => {
        if (result.isConfirmed) {
          deleteKey({
            kid: params.row.kid,
          });
        }
        console.log("kid ===> ", {
          kid: params.row.kid,
        });
      });
    };


    // REGION: RETURN TSX
    return (
      <>
        <Stack spacing={2} direction="row">
          <Button variant="contained" onClick={onEdit}>
            Update
          </Button>
          <Button variant="contained" color="error" onClick={onDelete}>
            Delete
          </Button>
        </Stack>
      </>
    );
  };

  // REGION: INIT COLUMNS
  const columns: GridColDef[] = [
    {
      field: "userId",
      headerName: "User ID",
      flex: 1,
      minWidth: 250,
      headerClassName: "centered",
    },
    {
      field: "email",
      headerName: "Email",
      flex: 1,
      minWidth: 250,
    },
    {
      field: "lastLogin",
      headerName: "Last Login",
      flex: 1,
      minWidth: 250,
    },
    {
      field: "status",
      headerName: "Status",
      flex: 1,
      minWidth: 200,
      headerAlign: 'center',
      align: 'center',
      renderCell: (params: any) => {
        if( params.row.status !== null ) {
          switch(params.row.status.toLowerCase()) {
            case "active": {
              return <Chip label="Active" color="success" variant="outlined" />
            }
            case "inactive": {
              return <Chip label="Inactive" color="warning" variant="outlined" />
            }
          }
        } else {
          return (
            ""
          )
        }
      },
    },
    {
      field: "action",
      headerName: "Action",
      flex: 1,
      minWidth: 320,
      headerAlign: "center",
      align: "center",
      sortable: false,
      renderCell: customCellRender,
    },
  ];

  // REGION: INIT ROWS
  console.log("data form table ===> ", data?.data);
  const dataRows =
    data?.data !== null && data?.data !== undefined ? data?.data : [];
  const rows = dataRows.map((row: any, index: any) => ({
    // uid: row.uc_ocsp_config_uid,
    id: index,
    uid: row.dsg_da_root_certificate_uid,
    userId: row.adm_user_uid,
    email: row.adm_user_email,
    lastLogin: row.adm_last_login,
    status: row.adm_user_status,
    firstName: row.adm_user_firstname,
    lastName: row.adm_user_lastname,
    phoneNumber: row.adm_user_mobile,
    accessGroup: row.adm_role_uid
  }));

  // REGION: RETURN TSX
  return (
    <>
      <Box sx={{ height: "650px" }}>
        <DataGrid
          rows={rows}
          columns={columns}
          disableColumnMenu
          hideFooter
          disableColumnSelector
          disableDensitySelector
          disableSelectionOnClick
        />
      </Box>
      <PaginationControl
        params={params}
        setParams={setParams}
        total={data.total_records}
      />
    </>
  );
};

export default UserAccountTable;
