import { yupResolver } from "@hookform/resolvers/yup";
import { Box, Button, Grid, Typography } from "@mui/material";
import { Stack } from "@mui/system";
import { useForm } from "react-hook-form";
import { InputForm } from "../../components/molecules/forms/index";
import { useLocation } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import DetailDataSkeleton from "../../components/template/skeleton/DetailDataSkeleton";
import WarningIcon from "@mui/icons-material/Warning";
import Swal from "sweetalert2";
import { renderToStaticMarkup } from "react-dom/server";
import { useRoleListQuery } from "../../store/feature/service/adminRole";
import pageMaster from "../../utils/validation/pageMaster";
import {
  useCreatePageMasterMutation,
  useUpdatePageMasterMutation,
} from "../../store/feature/service/PageMaster";
import { useDownloadGenDigsigEntryCompMutation } from "../../store/feature/service/generateDigsigApiSlice";
import generateDigDownload from "../../utils/validation/generateDigDownload";

const GeneratedDigsigDownload = () => {
  // REGION: INIT VARIABLES
  const navigate = useNavigate();
  const location = useLocation();
  const idParam = useParams();
  const { pathname, state } = location;
  const editFgroup = pathname.includes("edit");
  const [optAccGroup, setOtpAccGroup] = useState([]);
  const [csv, setCsv] = useState("");

  // const {
  //   data: dataRoleList,
  //   isError: errRoleList,
  //   isSuccess: succRoleList,
  // } = useRoleListQuery({});
  const [
    DownloadGenDigsigEntryComp,
    {
      data: dataDownload,
      isError: isErrorDownload,
      isSuccess: isSuccesDownload,
    },
  ] = useDownloadGenDigsigEntryCompMutation();

  // useUpdateDACertMutation
  // const [
  //   UpdatePageMaster,
  //   { data: dataUpdate, isError: isErrorUpdate, isSuccess: isSuccesUpdate },
  // ] = useUpdatePageMasterMutation();
  // REGION: INIT FORM DATA
  const {
    control,
    reset,
    setValue,
    handleSubmit,
    formState: { errors },
  } = useForm<{
    entryName: string;
    companyBatchId: string;
    csvFile: string;
  }>({
    reValidateMode: "onChange",
    // resolver: yupResolver(generateDigDownload),
    defaultValues: {
      entryName: "",
      companyBatchId: "",
      csvFile: "",
    },
  });

  useEffect(() => {
    if (state) {
      setValue("entryName", state.name);
      setValue("companyBatchId", state.accessUrl);
      setValue("csvFile", state.code);
      console.log("haha ===> ", state);
    }
  }, [state]);

  const swalAlert = () => {
    Swal.fire({
      icon: "warning",
      title: "Input can't empty",
      showCancelButton: false,
      confirmButtonText: "OK",
      confirmButtonColor: "#051438",
      text: "Input Must be Filled!!!",
    });
  };

  // REGION: DISPATCH INSERT & UPDATE DATA

  const onSubmit = (e: any) => {
    console.log("hahaha ===> ", e);
    if (e.companyBatchId.length === 0 && e.entryName.length === 0) {
      swalAlert();
      return;
    }
    let tempObj = {
      entryName: e.entryName,
      company: e.companyBatchId,
    };
    DownloadGenDigsigEntryComp(tempObj);
    // console.log("tempObj ===> ", tempObj);
    // if (editFgroup) {
    //   UpdatePageMaster({ id: idParam.id, body: tempObj });
    // } else {
    //   CreatePageMaster(tempObj);
    // }
  };

  const showFirstAlert = () => {
    Swal.fire({
      title: "Your changes won’t be saved",
      html: `
            <p style="text-align: left;">
              We won’t be able to save your data if you move away from this page.
            </p>
          `,
      icon: "question",
      showCancelButton: true,
      confirmButtonColor: "#d33",
      cancelButtonColor: "#808080",
      iconHtml: renderToStaticMarkup(
        <WarningIcon style={{ fontSize: "50px", color: "#d33" }} />
      ),
      confirmButtonText: "Discard Data",
      cancelButtonText: "Go back",
      reverseButtons: true,
    }).then(async (result) => {
      if (result.isConfirmed) {
        navigate("/page-master");
      }
    });
  };

  const handleRevoke = (e: any) => {
    console.log("handle revoke ===> ");
    showFirstAlert();
  };

  function downloadCsv(csvContent: any) {
    const byteCharacters = atob(csvContent);
        const byteNumbers = Array.from(byteCharacters, (char) =>
          char.charCodeAt(0)
        );
        const byteArray = new Uint8Array(byteNumbers);

        // Create a Blob from the byte array (CSV file)
        const blob = new Blob([byteArray], { type: "text/csv" });

        // Create a link element to trigger the download
        const link = document.createElement("a");
        link.href = URL.createObjectURL(blob);
        link.download = "digsig file.csv"; // Set the file name for download
        link.click();

        // Clean up the URL object after the download
        URL.revokeObjectURL(link.href);
  }

  const handleDownload = () => {
    downloadCsv(csv)
  };

  const successDownload = () => {
    Swal.fire({
      icon: "success",
      title: "Success Download",
      showCancelButton: false,
      confirmButtonText: "OK",
      confirmButtonColor: "#051438",
      text: "Success Download Generated Digsig",
    });
  };

  const errorDownload = () => {
    Swal.fire({
      icon: "error",
      title: "Error Download",
      showCancelButton: false,
      confirmButtonText: "OK",
      confirmButtonColor: "#051438",
      text: "Entry Name or Company Batch ID must be correct",
    });
  };

  useEffect(() => {
    if (dataDownload) {
      console.log("error ===> ", dataDownload.generated_digsig_csv.length);
      if (dataDownload.generated_digsig_csv.length < 60) {
        errorDownload();
        console.log("kurang ===> ");
      } else {
        successDownload();
        setCsv(dataDownload.generated_digsig_csv);
        console.log("lebih ===> ");
      }
    }
  }, [dataDownload]);
  // useEffect(() => {
  //   if (isSuccesUpdate) {
  //     Swal.fire({
  //       icon: "success",
  //       title: "Success Update",
  //       showCancelButton: false,
  //       confirmButtonText: "OK",
  //       confirmButtonColor: "#051438",
  //       text: "Success Update User Account ",
  //     }).then(() => navigate("/page-master"));
  //   }
  // }, [isSuccesUpdate]);

  // useEffect(() => {
  //   if (isErrorUpdate) {
  //     Swal.fire({
  //       icon: "error",
  //       title: "Error Update",
  //       showCancelButton: false,
  //       confirmButtonText: "OK",
  //       confirmButtonColor: "#051438",
  //       text: "Error Update User Account ",
  //     });
  //   }
  // }, [isErrorUpdate]);

  // REGION: RETURN TSX
  return (
    <DetailDataSkeleton
      // isLoading={isLoadingOcspConfig}
      // isLoadingEvent={isLoadingEvent}
      inputCount={6}
      titleWidth={"350px"}
    >
      <Box component="form" onSubmit={handleSubmit(onSubmit)} paddingY="20px">
        <Grid container spacing={1}>
          <Grid item lg={12}>
            <Typography variant="h3" fontFamily="Open Sans" mb={3}>
              Download generated digsig
            </Typography>
          </Grid>
          <Grid item lg={12}>
            <InputForm
              name="entryName"
              label="Entry Name"
              placeholder="Type Entry Name"
              disabled={false}
              isLoading={false}
              control={control}
              errors={errors}
              maxLength={500}
              required
            />
          </Grid>
          <Grid item lg={12}>
            <InputForm
              name="companyBatchId"
              label="Company Batch ID"
              placeholder="Type Company Batch ID"
              disabled={false}
              isLoading={false}
              control={control}
              errors={errors}
              required
            />
          </Grid>
          <Grid item lg={12}>
            <Stack
              spacing={2}
              direction="row"
              alignItems="center"
              justifyContent="flex-end"
            >
              <Button
                variant="contained"
                type="button"
                color="gray"
                onClick={handleRevoke}
              >
                Cancel
              </Button>
              <Button variant="contained" type="submit">
                Generated
              </Button>
            </Stack>
          </Grid>

          {csv.length > 60 && (
            <Grid item lg={12}>
              <hr />
              <Box sx={{ marginY: "20px" }}>
                <Typography variant="body16Regular">digsig file.csv</Typography>
              </Box>
              <br />
              <Button
                variant="contained"
                type="button"
                onClick={handleDownload}
              >
                Download
              </Button>
            </Grid>
          )}
        </Grid>
      </Box>
    </DetailDataSkeleton>
  );
};

export default GeneratedDigsigDownload;
